<template>
  <VCard>
    <VCardTitle>
      Поиск матрицы компании
    </VCardTitle>

    <VCardText>
      <VForm>
        <VContainer fluid>
          <VRow align="center">
            <VCol>
              <VAutocomplete
                :value="entity.companyId"
                :items="companies"
                :filter="customFilter"
                item-text="name"
                item-value="id"
                label="Компания"
                persistent-hint
                hint="UUID компании"
                @input="$emit('update:companyId', $event)"
              >
                <template #selection="{ item }">
                  <VListItemContent>
                    <VListItemTitle v-text="item.name" />
                    <VListItemSubtitle v-text="item.id" />
                  </VListItemContent>
                </template>

                <template #item="{ item }">
                  <VListItemContent>
                    <VListItemTitle v-text="item.name" />
                    <VListItemSubtitle v-text="item.id" />
                  </VListItemContent>
                </template>
              </VAutocomplete>
            </VCol>
          </VRow>
        </VContainer>
      </VForm>
    </VCardText>

    <VCardActions>
      <VSpacer />

      <VBtn
        text
        @click="handleCancel"
      >
        Отмена
      </VBtn>

      <VBtn
        color="primary"
        text
        @click="handleSubmit"
      >
        Найти
      </VBtn>
    </VCardActions>
  </VCard>
</template>

<script>
export default {
  name: 'CompanyMatrixForm',

  props: {
    entity: {
      type: Object,
      default: () => ({ companyId: null }),
    },

    companies: {
      type: Array,
      required: true,
    },
  },

  methods: {
    customFilter(item, queryText) {
      const name = item.name.toLowerCase();
      const uuid = item.id.toLowerCase();
      const searchText = queryText.toLowerCase();

      return name.indexOf(searchText) > -1 || uuid.indexOf(searchText) > -1;
    },

    handleCancel() {
      this.$emit('cancel');
    },

    handleSubmit() {
      this.$emit('submit', this.entity);
    },
  },
};
</script>
